<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Listas de Proveedores</h4>
            <div class="small text-muted">Administrador de las listas de precios por proveedores</div>
          </b-col>                            
        </b-row>
      </b-card>

      <b-card>
        <b-row>
          <b-col sm="10">
            <b-row>                                                       
              <b-col sm="3">
                <v-select :options="arr.filters.suppliers" v-model="filters.suppliers" placeholder="Proveedores" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>  
              <b-col sm="2">
                <b-button variant="outline-dark" @click="loadSuppliers()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>              
            </b-row>
          </b-col>

          <b-col sm="2" v-if="table.items.length">
            <b-row>
              <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
                <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                  <i class="fa fa-filter"></i> Mostrar Filtros
                </b-button>
              </b-col>
              <b-col sm="12" class="d-none d-md-block" v-else>
                <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                  <i class="fa fa-window-close"></i>
                </b-button>
              </b-col>
            </b-row>
          </b-col>

        </b-row>
      </b-card>

      <b-row>
        <b-col lg="3">
          <b-card>
            <vue-tree-list
              @click="selectedItem"              
              :model="suppliersTree"
              v-bind:default-expanded="false"              
            >                      
              <template v-slot:leafNameDisplay="slotProps">                
                <span>
                  &nbsp;&nbsp;{{ slotProps.model.name }}
                </span>
              </template>              

            </vue-tree-list>                     

            <div v-if="suppliers">
              <b-alert show v-if="!suppliers.length" variant="warning">
                No se encontraron proveedores con listas de precios cargadas
              </b-alert>
            </div>
          </b-card>          
        </b-col>        
        <b-col lg="9">
          <b-card>
            <b-row v-if="table.mostrarFiltros">
              <b-col sm="3">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Ingrese código o nombre" v-model="filterQuery.text"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>   
              <b-col sm="3">
                <v-select :options="arr.select.linkedProduct" v-model="filterQuery.linkedProduct" placeholder="Producto Vinculado" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>
              <b-col sm="3">
                <v-select :options="arr.select.lastUpdate" v-model="filterQuery.lastUpdate" placeholder="Ultima Actualización" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>              
              <b-col sm="2">
                <b-button variant="outline-dark" @click="selectedItem(filterQuery, true)" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="8">
                <b>{{this.itemSelected}}</b>
                <span class="crud-price-list-suppliers-download">
                  <b-link v-if="itemSelected" title="Descargar lista de precios en formato CSV para el proveedor seleccionado">
                    <export-excel                  
                      :data = "arr.export"
                      worksheet = "Productos Proveedor"
                      name = "list-cost-products.xls"
                      v-if="arr.export.length">
                      <b-icon icon="download" animation="throb" variant="success"></b-icon>
                    </export-excel>  
                    <b-icon v-else 
                            icon="download" 
                            @click="prepareExport()">
                    </b-icon>   
                  </b-link>                                                                               
                </span>
              </b-col>
              <b-col sm="4">
                <b-button @click="openImportCSV()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Importar Lista de Precios">
                  <i class="fa fa-file-excel-o"></i> Gestor de Importaciones                  
                </b-button>  
              </b-col>                
              <b-col sm="12" class="mt-2">

                <b-table class="mb-0"
                      style="min-height:130px;"
                      responsive
                      head-variant="dark"
                      :hover="true"
                      :small="true"
                      :fixed="true"
                      :items="table.items"
                      :fields="table.fields"
                      :filter="table.filter"
                      :current-page="table.currentPage"
                      :per-page="table.perPage"
                      :busy="table.isBusy"                     
                      v-if="table.items.length || table.isBusy">   
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template> 

                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Cargando...</strong>
                    </div>
                  </template>

                  <template v-slot:cell(name)="data">
                    {{data.item.name}}
                    <b-icon icon="exclamation-diamond-fill" 
                            class="rounded-circle" 
                            variant="danger" 
                            title="No tiene relación con ningún Producto de Venta"
                            v-if="!data.item.products_id">
                    </b-icon>
                  </template>

                  <template v-slot:cell(unit_measurement)="data">   
                    <div :title="data.item.unit_measurement_purchase.name">
                      {{ data.item.unit_measurement_purchase.reference }}
                    </div>
                  </template>

                  <template v-slot:cell(price_list)="data">
                    <b>
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.price_list)}}                      
                    </b>
                  </template>

                  <template v-slot:cell(percentage_discount)="data">
                    <span v-if="getDiscount(data.item,1)"> -{{getDiscount(data.item,1)}} </span>
                    <span v-if="getDiscount(data.item,2)"> -{{getDiscount(data.item,2)}} </span>
                    <span v-if="getDiscount(data.item,3)"> -{{getDiscount(data.item,3)}} </span>
                    
                    <span v-if="getDiscount(data.item,1) || getDiscount(data.item,2) || getDiscount(data.item,3)">
                      %
                    </span>
                  </template>

                  <template v-slot:cell(additional)="data">
                    <span v-if="getAdditional(data.item,1)"> +{{getAdditional(data.item,1)}} </span>
                    <span v-if="getAdditional(data.item,2)"> +{{getAdditional(data.item,2)}} </span>                    
                    
                    <span v-if="getAdditional(data.item,1) || getAdditional(data.item,2)">
                      %
                    </span>
                  </template>

                  <template v-slot:cell(extra)="data">
                    <span v-if="data.item.additional">
                      + {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.additional)}}                                          
                    </span>                                        
                  </template>           

                  <template v-slot:cell(cost)="data">
                    <b>
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format( data.item.price_cost )}}                      
                    </b>
                  </template>    

                  <template v-slot:cell(default)="data">
                    <b-badge v-if="data.item.default" variant="dark">
                      SI
                    </b-badge>
                  </template>

                  <template v-slot:cell(last_update)="data">
                    <span v-if="diffLastUpdate(data.item) <= 15" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                      <b-icon icon="circle-fill" font-scale="1" variant="success"></b-icon>
                    </span>                    
                    <span v-if="diffLastUpdate(data.item) > 15 && diffLastUpdate(data.item) <= 30" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                      <b-icon icon="circle-fill" font-scale="1"  variant="warning"></b-icon>
                    </span>
                    <span v-if="diffLastUpdate(data.item) > 30" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                      <b-icon icon="circle-fill" animation="throb" font-scale="1" variant="danger"></b-icon>
                    </span>                    
                  </template>                                    
                                
                  <template v-slot:cell(f_action)="data">
                    <b-dropdown right text="" size="sm" variant="outline-dark" class="pull-right crud-price-list-suppliers-table-action">
                      <b-dropdown-item v-if="data.item.products_id" @click="showCompare(data.item)">
                        <i class="fa fa-columns ml-0 mr-0"></i> Comparar Precios
                      </b-dropdown-item>                                                        
                      <b-dropdown-item @click="showHistory(data.item)">
                        <i class="fa fa-history ml-0 mr-0"></i> Historial
                      </b-dropdown-item>
                      <b-dropdown-header>Acciones</b-dropdown-header>
                      <b-dropdown-item v-if="!data.item.default" @click="setDefault(data.item)">
                        <i class="fa fa-dot-circle-o ml-0 mr-0"></i> Marcar Por Default
                      </b-dropdown-item>                                                 
                      <b-dropdown-item v-if="data.item.default" @click="showConvert(data.item)">
                        <i class="fa fa-clone ml-0 mr-0"></i> Configurar Producto
                      </b-dropdown-item>                      
                    </b-dropdown>
                  </template>
                </b-table>
                <b-alert show variant="info" v-else>
                  No hay productos para mostrar
                </b-alert>   

              </b-col>
              <b-col class="mt-4">
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="selectedItem(filterQuery, true)" />
                </nav>                
              </b-col>              
            </b-row>
          </b-card>
        </b-col>
      </b-row> 

      <b-row>
        <b-col lg="12">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>                      
            </b-row>
          </b-card>
        </b-col>
      </b-row>    

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD IMPORTACIONES -->
      <b-modal  v-model="modal.formImport.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="lg">
        <div slot="modal-header">
          {{this.modal.formImport.title}}
        </div>
        
        <b-tabs pills card vertical>
          <b-tab title="Lista de Precios" @click="crud.formImport.type='import-costs-suppliers-products'" active >
            <b-card-text>
              <b-row>       
                <b-col md="12" class="mb-2">
                  <b-alert show variant="info">
                    <h4 class="alert-heading">Importación de Lista de Precio por Proveedor</h4>
                    
                    <div>
                      <b-icon icon="arrow-right-short"/> El sistema importará todos los registros cargados en el archivo.
                    </div>
                    <div>
                      <b-icon icon="arrow-right-short"/> Esta importación solo permite agregar <b>Productos de Proveedores</b>.
                    </div>                      
                    <div>
                      <b-icon icon="arrow-right-short"/> El <b>código de producto ingresado</b>, se vinculará con el <b>Producto Existente</b>, sino crea un <b>Producto Nuevo</b>.
                    </div>                    
                    <div>
                      <b-icon icon="arrow-right-short"/> Si un <b>producto previamente cargado</b>, no se agrega en la importación, el sistema <b>inactivara</b> el producto en la lista de precios del proveedor.
                    </div>                                        
                    <div>
                      <b-icon icon="arrow-right-short"/> Si el <b>producto inactivado</b> tiene una relación unica con un producto de venta, tambien lo <b>inactivará</b>.
                    </div>                                                            
                    <div>
                      <b-icon icon="arrow-right-short"/> En caso que el <b>producto de venta</b> tenga varios proveedores, solo se <b>inactivará</b> el producto para ese proveedor
                    </div>                                                                                
                    <div>
                      <b-icon icon="arrow-right-short"/> Es posible cargar una <b>utilidad diferencial</b> por <b>producto</b> para una <b>lista de precios</b>.
                    </div>                      

                    <hr>

                    <div class="mb-0">
                      Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/costs/csv-format-costs-suppliers-products.csv')"><b>formato de ejemplo</b></a> para esta importación.
                    </div>                                                                  
                  </b-alert>                  
                </b-col>
                <b-col md="12" class="mb-2">
                  <FindObject type="suppliers" 
                              @select-object="loadSuppliersImportCSV($event)" 
                              :valueID="crud.formImport.suppliers_id"
                              render="search"/>                  
                </b-col>
                <b-col md="12" class="mb-2">                  
                  <b-form-group label="Archivo CSV [Lista de Precios]">                
                    <b-form-file v-model="crud.formImport.file"
                                  size="sm"
                                  :state="Boolean(crud.formImport.file)"
                                  placeholder="Elija un archivo o sueltelo aquí..."
                                  drop-placeholder="Suelta el archivo aquí...">
                    </b-form-file>                    
                  </b-form-group>         
                </b-col> 
              </b-row>              
            </b-card-text>
          </b-tab>
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formImport.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="importCSV()">Importar</b-button>          
        </div>

      </b-modal>

      <!-- HISTORIAL -->
      <b-modal v-model="modal.formHistory.active"
               header-bg-variant="dark"
               header-text-variant="white"
               size="lg">
        <div slot="modal-header">
          {{this.modal.formHistory.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b>{{this.itemSelectedHistory.toUpperCase()}}</b>
          </b-col>
          <b-col lg="12">
            <b-table class="mb-0" 
                  responsive
                  head-variant="dark"
                  :hover="true"
                  :small="true"
                  :fixed="true"
                  :items="tableHistory.items"
                  :fields="tableHistory.fields"
                  :filter="tableHistory.filter"
                  :current-page="tableHistory.currentPage"
                  :per-page="tableHistory.perPage"
                  :busy="tableHistory.isBusy"                     
                  v-if="tableHistory.items.length || tableHistory.isBusy">   
                
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 

              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Cargando...</strong>
                </div>
              </template>

              <template v-slot:cell(date)="data">
                {{moment(data.item.date).format('DD/MM/YYYY')}}
              </template>

              <template v-slot:cell(unit_measurement)="data">   
                <div :title="data.item.unit_measurement_purchase.name">
                  {{ data.item.unit_measurement_purchase.reference }}
                </div>
              </template>

              <template v-slot:cell(price_list)="data">
                <b>
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.price_list)}}                      
                </b>
              </template>

              <template v-slot:cell(percentage_discount)="data">
                <span v-if="getDiscount(data.item,1)"> -{{getDiscount(data.item,1)}} </span>
                <span v-if="getDiscount(data.item,2)"> -{{getDiscount(data.item,2)}} </span>
                <span v-if="getDiscount(data.item,3)"> -{{getDiscount(data.item,3)}} </span>
                
                <span v-if="getDiscount(data.item,1) || getDiscount(data.item,2) || getDiscount(data.item,3)">
                  %
                </span>
              </template>

              <template v-slot:cell(additional)="data">
                <span v-if="getAdditional(data.item,1)"> +{{getAdditional(data.item,1)}} </span>
                <span v-if="getAdditional(data.item,2)"> +{{getAdditional(data.item,2)}} </span>                    
                
                <span v-if="getAdditional(data.item,1) || getAdditional(data.item,2)">
                  %
                </span>
              </template>

              <template v-slot:cell(extra)="data">
                <span v-if="data.item.additional">
                  + {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.additional)}}                                          
                </span>                                        
              </template>           

              <template v-slot:cell(cost)="data">
                <b>
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format( data.item.price_cost )}}                      
                </b>
              </template>    
            </b-table>
            <b-alert show variant="info" v-else>
              No se encontró el historial
            </b-alert>               
          </b-col>
        </b-row>  

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="closeHistory()">Cerrar</b-button>
        </div>                     
      </b-modal>

      <!-- CONVERSION -->
      <b-modal v-model="modal.formConvert.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formConvert.title}}
        </div>


        <b-tabs card>
          <b-tab no-body title="Producto">
            <b-row>
              <b-col lg="12">
                <b>{{this.itemSelectedConvert.toUpperCase()}}</b>
              </b-col>
              <b-col lg="12">
                <b-form-radio-group
                  v-model="crud.formConvert.type"
                  :options="arr.typeConvert"
                  class="mb-3"
                  value-field="item"
                  text-field="name"
                  @input="changeTypeConvert()"  
                  :disabled="!crud.formConvert.changeType"            
                ></b-form-radio-group>            
              </b-col>
              <b-col lg="12" v-if="crud.formConvert.type == 'NUEVO'">
                <b-form-group label="Código">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.formConvert.code"
                                placeholder="Ingresar un código">
                  </b-form-input>
                </b-form-group>            
              </b-col>          
              <b-col lg="12" v-if="crud.formConvert.type == 'ASOCIAR'">
                <FindObject type="products" 
                            @select-object="loadProductsConvert($event)" 
                            :valueID="crud.formConvert.products_id"
                            render="search"/>              
              </b-col>
            </b-row>
          </b-tab>
          <b-tab no-body title="Precio">        
            <b-row>
              <b-col lg="5">
                <b-form-group :label="'Precio de Lista ($)'">
                  <b-form-input type="number"
                                size="sm"
                                step="0.01"
                                v-model="crud.formConvert.price_list"
                                placeholder="Precio de Lista">
                  </b-form-input>            
                </b-form-group>
              </b-col>                  
            </b-row>
          </b-tab>
          <b-tab no-body title="Descuentos">
            <b-row>
              <b-col lg="12">
                <b-row>
                  <b-col lg="4">
                    <b-form-group :label="'Descuento 1 (%)'">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formConvert.percentage_discount"
                                    placeholder="Descuento">
                      </b-form-input>            
                    </b-form-group>
                  </b-col>
                  <b-col lg="4">
                    <b-form-group :label="'Descuento 2 (%)'">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formConvert.percentage_discount_1"
                                    placeholder="Descuento">
                      </b-form-input>            
                    </b-form-group>
                  </b-col>
                  <b-col lg="4">
                    <b-form-group :label="'Descuento 3 (%)'">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formConvert.percentage_discount_2"
                                    placeholder="Descuento">
                      </b-form-input>            
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>              
            </b-row>
          </b-tab>
          <b-tab no-body title="Adiccionales">
            <b-row>
              <b-col lg="12">
                <b-row>
                  <b-col lg="4">
                    <b-form-group :label="'Adiccional 1 (%)'">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formConvert.additional_percentage"
                                    placeholder="Adiccional">
                      </b-form-input>            
                    </b-form-group>
                  </b-col>
                  <b-col lg="4">
                    <b-form-group :label="'Adiccional 2 (%)'">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formConvert.additional_percentage_1"
                                    placeholder="Adiccional">
                      </b-form-input>            
                    </b-form-group>
                  </b-col>              
                  <b-col lg="4">
                    <b-form-group :label="'Adicional ($)'">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formConvert.additional"
                                    placeholder="Adicional">
                      </b-form-input>            
                    </b-form-group>
                  </b-col>      
                </b-row>
              </b-col> 
            </b-row>
          </b-tab>          
          <b-tab no-body title="U.Medidas">
            <b-row>
              <b-col lg="8">
                <FindObject type="unitMeasurement" 
                            tag="Unidad de Medida de Compra"
                            @select-object="loadUnitMeasurementPurchase($event)" 
                            :valueID="crud.formConvert.unit_measurement_purchase_id"
                            render="search"/>                       
              </b-col>              
              <b-col lg="8">
                <FindObject type="unitMeasurement" 
                            tag="Unidad de Medida de Venta"
                            @select-object="loadUnitMeasurementSale($event)" 
                            :valueID="crud.formConvert.unit_measurement_sale_id"
                            render="search"/>                       
              </b-col>
              <b-col lg="4" v-if="crud.formConvert.unit_measurement_purchase_id != crud.formConvert.unit_measurement_sale_id">
                <b-form-group label="Variable" description="Variable de Conversión">
                  <b-form-input type="number"
                                size="sm"
                                step="0.01"
                                v-model="crud.formConvert.unit_measurement_sale_conversion"
                                placeholder="Ingrese un valor">
                  </b-form-input>            
                </b-form-group>
              </b-col>                  
            </b-row>
          </b-tab>                    
        </b-tabs>                    

        <b-row>
          <b-col lg="12">
            <hr>
          </b-col>

          <b-col lg="12" :key="convertPriceForceUpdateKey">
            <b-row v-for="element in arr.listPrices" :key="element.id">
              <b-col lg="7">
                <div class="mt-3">
                  <b>Lista:</b> {{element.name}} ({{element.reference}}) <br>
                  <b>Defecto:</b> {{Intl.NumberFormat('es-AR',{style:'percent'}).format( getUtility(element) )}}
                </div>
              </b-col>
              <b-col lg="5">
                <b-form-group :label="'Utilidad Diferenciada'">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.formConvert.lists[element.id]"
                                placeholder="Ingresar utilidad diferencial para esta lista">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="12">
                <hr class="mt-0 mb-2">
              </b-col>
            </b-row>
          </b-col>
        </b-row>  

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formConvert.active=false">Cerrar</b-button>
          <b-button variant="dark" @click="saveConvert()">Configurar</b-button>        
        </div>                     
      </b-modal>

      <!-- COMPARE PRICE -->
      <b-modal v-model="modal.formCompare.active"
               header-bg-variant="dark"
               size="lg"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formCompare.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b>{{this.itemSelectedCompare.toUpperCase()}}</b>
          </b-col>
          <b-col lg="12">
            <b-table class="mb-0" 
                  responsive
                  head-variant="dark"
                  :hover="true"
                  :small="true"
                  :fixed="true"
                  :items="tableCompare.items"
                  :fields="tableCompare.fields"
                  :filter="tableCompare.filter"
                  :current-page="tableCompare.currentPage"
                  :per-page="tableCompare.perPage"
                  :busy="tableCompare.isBusy"                     
                  v-if="tableCompare.items.length || tableCompare.isBusy">   
                
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 

              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Cargando...</strong>
                </div>
              </template>

              <template v-slot:cell(supplier)="data">
                {{data.item.supplier.name}}
              </template>

              <template v-slot:cell(unit_measurement)="data">   
                <div :title="data.item.unit_measurement_purchase.name">
                  {{ data.item.unit_measurement_purchase.reference }}
                </div>
              </template>

              <template v-slot:cell(price_list)="data">
                <b>
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.price_list)}}                      
                </b>
              </template>

              <template v-slot:cell(percentage_discount)="data">
                <span v-if="getDiscount(data.item,1)"> -{{getDiscount(data.item,1)}} </span>
                <span v-if="getDiscount(data.item,2)"> -{{getDiscount(data.item,2)}} </span>
                <span v-if="getDiscount(data.item,3)"> -{{getDiscount(data.item,3)}} </span>
                
                <span v-if="getDiscount(data.item,1) || getDiscount(data.item,2) || getDiscount(data.item,3)">
                  %
                </span>
              </template>

              <template v-slot:cell(additional)="data">
                <span v-if="getAdditional(data.item,1)"> +{{getAdditional(data.item,1)}} </span>
                <span v-if="getAdditional(data.item,2)"> +{{getAdditional(data.item,2)}} </span>                    
                
                <span v-if="getAdditional(data.item,1) || getAdditional(data.item,2)">
                  %
                </span>
              </template>

              <template v-slot:cell(extra)="data">
                <span v-if="data.item.additional">
                  + {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.additional)}}                                          
                </span>                                        
              </template>           

              <template v-slot:cell(cost)="data">
                <b>
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format( data.item.price_cost )}}                      
                </b>
              </template>    

              <template v-slot:cell(default)="data">
                <b-badge v-if="data.item.default" variant="dark">
                  SI
                </b-badge>
              </template>

              <template v-slot:cell(last_update)="data">
                <span v-if="diffLastUpdate(data.item) <= 15" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                  <b-icon icon="circle-fill" font-scale="1" variant="success"></b-icon>
                </span>                    
                <span v-if="diffLastUpdate(data.item) > 15 && diffLastUpdate(data.item) <= 30" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                  <b-icon icon="circle-fill" font-scale="1"  variant="warning"></b-icon>
                </span>
                <span v-if="diffLastUpdate(data.item) > 30" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                  <b-icon icon="circle-fill" animation="throb" font-scale="1" variant="danger"></b-icon>
                </span>                    
              </template>               
            </b-table>
            <b-alert show variant="info" v-else>
              No se encontró ningún producto
            </b-alert>               
          </b-col>
        </b-row>  

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="closeCompare()">Cerrar</b-button>
        </div>                     
      </b-modal>      
    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Funciones from '@/handler/funciones' 
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import FindObject from '@/components/inc/find/findObject'
  import moment from 'moment'      

  import Vue from 'vue'
  import { VueTreeList, Tree, TreeNode } from 'vue-tree-list'
  Vue.use(VueTreeList)  

  export default {
    components: {
      FindObject,
      VueTreeList,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.COSTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudPriceListSuppliers',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [                       
            {key: 'code', label: 'Código', class:'text-left align-middle text-truncate', width:"7%"},
            {key: 'name', label: 'Nombre', class:'text-left align-middle text-truncate', width:"18%"},            
            {key: 'category', label: 'Categoría', class:'text-left align-middle text-truncate', width:"12%"},
            {key: 'unit_measurement', label: 'U.Med.', class:'text-center align-middle text-truncate', width:"4%"},            
            {key: 'price_list', label: 'Lista', class:'text-right align-middle', width:"9%"},
            {key: 'percentage_discount', label: 'Descuento', class:'text-right align-middle', width:"10%"},
            {key: 'additional', label: 'Addicional', class:'text-right align-middle', width:"10%"},
            {key: 'extra', label: 'Extra', class:'text-right align-middle', width:"10%"},
            {key: 'cost', label: 'Compra', class:'text-right align-middle', width:"10%"},
            {key: 'last_update', label: '', class:'text-center align-middle', width:"2%"},
            {key: 'default', label: 'Default', class:'text-center align-middle', width:"5%"},            
            {key: 'f_action', label:'', class:'text-right align-middle', width:"3%"},
          ],          
          filter: null,
          mostrarFiltros: false,
          tablePaginate: null,
          currentPage: 1,
          perPage: 0,
          isBusy: false,          
        },   
        tableHistory: {
          items: [],
          fields: [            
            {key: 'date', label: 'Fecha', class:'text-left align-middle', width:"15%"},
            {key: 'unit_measurement', label: 'U.Med.', class:'text-center align-middle text-truncate', width:"10%"},            
            {key: 'price_list', label: 'Lista', class:'text-right align-middle', width:"10%"},
            {key: 'percentage_discount', label: 'Descuento', class:'text-right align-middle', width:"20%"},
            {key: 'additional', label: 'Addicional', class:'text-right align-middle', width:"20%"},
            {key: 'extra', label: 'Extra', class:'text-right align-middle', width:"10%"},
            {key: 'cost', label: 'Compra', class:'text-right align-middle', width:"15%"},
          ],        
          tablePaginate: true,
          currentPage: 1,
          perPage: 50,
          isBusy: false,          
        },
        tableCompare: {
          items: [],
          fields: [            
            {key: 'supplier', label: 'Proveedor', class:'text-left align-middle text-truncate', width:"18%"},
            {key: 'unit_measurement', label: 'U.Med.', class:'text-center align-middle text-truncate', width:"5%"},            
            {key: 'price_list', label: 'Lista', class:'text-right align-middle', width:"13%"},
            {key: 'percentage_discount', label: 'Descuento', class:'text-right align-middle', width:"15%"},
            {key: 'additional', label: 'Addicional', class:'text-right align-middle', width:"15%"},
            {key: 'extra', label: 'Extra', class:'text-right align-middle', width:"13%"},
            {key: 'cost', label: 'Compra', class:'text-right align-middle', width:"13%"},            
            {key: 'last_update', label: '', class:'text-center align-middle', width:"2%"},            
            {key: 'default', label: 'Default', class:'text-center align-middle', width:"6%"},
          ],        
          tablePaginate: true,
          currentPage: 1,
          perPage: 50,
          isBusy: false,          
        },             
        crud: {          
          formImport: {
            suppliers: null,
            suppliers_id: 0,
            file: '',
            type: ''            
          },
          formConvert: {
            id: 0,
            type: 'NUEVO',
            code: '',
            products_id: 0,
            products: null,
            changeType: false,
            lists: [],
            price_list: 0,
            percentage_discount: 0,
            percentage_discount_1: 0,
            percentage_discount_2: 0,
            additional: 0,
            additional_percentage: 0,
            additional_percentage_1: 0,
            unit_measurement_purchase_id: 0,
            unit_measurement_purchase: null,
            unit_measurement_sale_id: 0,
            unit_measurement_sale: null,            
            unit_measurement_sale_conversion: 0,   
          }         
        },                      
        arr: {
          listProducts: [],
          listPrices: [],
          export: [],
          typeConvert: [
            { item: 'NUEVO', name: 'Nuevo Producto' },
            { item: 'ASOCIAR', name: 'Configurar Producto' },
          ],          
          filters : {
            suppliers: [],            
          }, 
          select: {
            linkedProduct:[
              {code:true, label: 'Vinculados'},
              {code:false, label: 'Sin Vincular'},              
            ],               
            lastUpdate:[
              {code:'0-15', label: 'Últimos 15 días'},
              {code:'15-30', label: 'Entre 15 y 30 días'},
              {code:'30-9999', label: 'Mas de 30 días'},              
            ],                           
          }                               
        },           
        filters: {
          suppliers: null,
        },  
        filterQuery: {
          suppliers_id: 0,
          category: '',
          text: '',
          linkedProduct: null,
          lastUpdate: null,
          paginate: true,
        }, 
        modal: {
          formImport: {
            active: false,
            title: ''
          },
          formHistory: {
            active: false,
            title: ''            
          },
          formConvert: {
            active: false,
            title: ''                        
          },
          formCompare: {
            active: false,
            title: ''                        
          },          
        }, 
        convertPriceForceUpdateKey: 0,
        itemSelected: '',
        itemSelectedHistory: '',
        itemSelectedConvert: '',
        itemSelectedCompare: '',
        suppliers: null,
        suppliersTree: new Tree([]),         
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },     
    mounted() {         
      this.loadSuppliers()         
      this.filterLoad()      
      this.loadListsPrices()
    },     
    computed: {
      getCurrency() {
        return 'ARS'
      }
    },   
    watch: {        
      'filterQuery.suppliers_id': function (newQuestion, oldQuestion) {                 
        this.arr.export = []
      }
    },     
    methods: {
      // IMPORT BY CSV
      openImportCSV() {
        this.crud.formImport.type = 'import-costs-suppliers-products'
        this.crud.formImport.file = ''
        this.crud.formImport.suppliers_id= 0
        this.crud.formImport.suppliers= null
            
        this.modal.formImport.title = "Gestor de Importaciones"
        this.modal.formImport.active = true
      },
      importCSV() {     
        this.$bvModal.msgBoxConfirm('¿Continuar con la importación?', {
          title: 'Importar desde CSV',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'IMPORTAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {            
            let loader = this.$loading.show();

            var file = new FormData();
            file.append("file", this.crud.formImport.file);            
              
            var result = serviceAPI.importarSuppliersProductosPrices(JSON.stringify(this.crud.formImport), file);

            result.then((response) => {              
              this.modal.formImport.active = false              
              loader.hide()
              this.filterLoad()
              this.loadSuppliers()
              this.selectedItem(this.filterQuery, true)
              this.$awn.success("Archivo importado con éxito");
            })
            .catch(error => {                  
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {                 
          this.$awn.alert(Error.showError(error));
        })        
      },
      goDownloadFormat(value) {
        var win = window.open(Helper.getPathPublic(value), '_blank');        
        win.focus();
      },
      loadSuppliersImportCSV(object) {
        if(object){
          this.crud.formImport.suppliers = object
          this.crud.formImport.suppliers_id = object.id
        } else {
          this.crud.formImport.suppliers = null
          this.crud.formImport.suppliers_id = 0
        }
      },

      // FILTER
      filterLoad() {   
        this.filterLoadSuppliers()          
      },
      filterLoadSuppliers() {
        var result = serviceAPI.obtenerProveedoresOnlyImport()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.suppliers = []
          data.forEach(element => {            
            this.arr.filters.suppliers.push({ code: element.id, label: element.name })            
          });          
        })   
      },      

      // TREE
      loadSuppliers() {
        let loader = this.$loading.show()   
   
        var result = serviceAPI.obtenerProveedoresForTree(this.filters)
        result.then((response) => {
          loader.hide()
          
          this.arr.listProducts = [];
          this.suppliers = [];  
          this.suppliersTree = new Tree(this.suppliers);  

          var data = response.data                 
          if(Object.entries(data).length) {
            var arrSuppliers = []
            Object.entries(data).forEach(([key, element]) => {            
              arrSuppliers.push({
                id: element.id,
                name: element.name,
                category: element.category,
              })
            });
              
            arrSuppliers = Funciones.sortArrayInteger(arrSuppliers,'id','asc')              
                        
            var groupCat = []            
            arrSuppliers.forEach(element => {
              if(groupCat[element.id] == undefined) {
                groupCat[element.id] = []                 
                groupCat[element.id].id = element.id    
                groupCat[element.id].name = element.name    
                groupCat[element.id].children = []            
              }
            });            
            groupCat = groupCat.filter(Boolean)
            
            this.configTree(groupCat, arrSuppliers)
          }           
        })
        .catch(error => {         
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });         
      },      
      configTree(group, data) {               
        this.suppliers = []             

        group.forEach((element, index) => {          
          this.suppliers.push({            
            id: element.id,                  
            name: element.name,            
            dragDisabled: true,
            addTreeNodeDisabled: true,
            addLeafNodeDisabled: true,
            editNodeDisabled: true,
            delNodeDisabled: true,                     
            children: [],
          })

          this.getChildren(this.suppliers[index], data)
        })                  

        this.suppliersTree = new Tree(this.suppliers)                
      },         
      getChildren(obj, data) {                                       
        if(data) {        
          data.forEach((element, index) => {                            
            if(obj.id == element.id) {                                                                                  
              var existe = false
              obj.children.forEach(item => {                                
                if(item == element.category) {
                  existe = true
                }
              });

              if(!existe) {
                obj.children.push({
                  id: index,      
                  name: element.category,                
                  dragDisabled: true,
                  addTreeNodeDisabled: true,
                  addLeafNodeDisabled: true,
                  editNodeDisabled: true,
                  delNodeDisabled: true,                  
                  isLeaf: true,                            
                })    
              }                                                
            }  
            
          });     
        }   
      },      
      selectedItem(params, forceSelected=false) {         
        this.table.isBusy = true

        if(!forceSelected) {
          this.filterQuery = null
          if(params.pid) {
            this.filterQuery = {
              suppliers_id: params.pid,
              category: params.name
            }

            this.itemSelected = params.parent.name.toUpperCase() + ' | ' + params.name.toUpperCase()
          } else {
            this.filterQuery = {
              suppliers_id: params.id,          
            }

            this.itemSelected = params.name.toUpperCase()
          }   
        }

        this.filterQuery.paginate = true
        var result = serviceAPI.obtenerProductsByFilter(this.filterQuery, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data       

          this.table.tablePaginate = data      

          this.table.items = data.data
          this.table.isBusy = false
        })
      },    
      prepareExport() {        
        this.$bvModal.msgBoxConfirm('¿Generar lista de precios para el proveedor seleccionado?', {
          title: 'Generar lista de precios',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {          
            this.$awn.info("Estamos procesando el archivo, por favor espere...");
            let loader = this.$loading.show();

            this.arr.export = []        
            
            this.filterQuery.paginate = false
            var result = serviceAPI.obtenerProductsByFilter(this.filterQuery, null)        
            result.then((response) => {              
              this.arr.listProducts = response.data
                                
              if(this.arr.listProducts.length) {
                this.arr.listProducts.forEach(element => { 

                  var arrExport = []
                  arrExport['code'] = element.code
                  arrExport['name'] = element.name
                  arrExport['category'] = element.category
                  arrExport['unit_measurement_purchase'] = element.unit_measurement_purchase.reference
                  arrExport['price_list'] = element.price_list
                  arrExport['percentage_discount'] = element.percentage_discount
                  arrExport['percentage_discount-1'] = element.percentage_discount_1
                  arrExport['percentage_discount-2'] = element.percentage_discount_2
                  arrExport['additional'] = element.additional
                  arrExport['additional_percentage'] = element.additional_percentage
                  arrExport['additional_percentage-1'] = element.additional_percentage_1
                  arrExport['default'] = element.default
                  arrExport['code_system'] = ''
                  arrExport['unit_measurement_sale'] = ''
                  arrExport['unit_measurement_sale_conversion'] = ''

                  if(element.product) {
                    arrExport['code_system'] = element.product.code
                    
                    if(element.product.unit_measurement_sale) {                
                      arrExport['unit_measurement_sale'] = element.product.unit_measurement_sale.reference
                      arrExport['unit_measurement_sale_conversion'] = element.product.unit_measurement_sale_conversion
                    }            

                    if(element.product.prices) {     
                      element.product.prices.forEach(element1 => {
                        arrExport['list-' + element1.list.reference] = element1.utility                  
                      });
                    }
                  } 
                                
                  this.arr.export.push(arrExport)
                }); 
                
                loader.hide()
                this.$awn.success("Finalizamos el proceso, por favor descargue el archivo.");
              }
            })
            .catch(error => {                     
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })          
          }
        })
        .catch(error => {    
          this.$awn.alert(Error.showError(error));
        })              
      },

      // TABLA
      getRowCount (items) {
        return items.length
      },      
      getDiscount(item, nivel=1) {
        if(nivel==1) {
          return parseFloat(item.percentage_discount)
        }        
        if(nivel==2) {
          return parseFloat(item.percentage_discount_1)
        }        
        if(nivel==3) {
          return parseFloat(item.percentage_discount_2)
        }                        
      },
      getAdditional(item, nivel=1) {
        if(nivel==1) {
          return parseFloat(item.additional_percentage)
        }        
        if(nivel==2) {
          return parseFloat(item.additional_percentage_1)
        }                
      },
      diffLastUpdate(item) {
        var dateNow = moment();
        var dateLast = moment(item.updated_at);        
        return dateNow.diff(dateLast, 'days') + 1 
      },

      // HISTORICO PRECIOS
      showHistory(item) {              
        this.tableHistory.isBusy = true
        this.itemSelectedHistory = this.itemSelected + ' - (' + item.code + ') ' + item.name
        this.tableHistory.items = item.history.reverse()        

        this.modal.formHistory.title = "Historial de Precios"
        this.modal.formHistory.active = true
        this.tableHistory.isBusy = false
      },
      closeHistory() {
        this.modal.formHistory.active = false
      },     

      // CONVERSION PRODUCTOS
      loadListsPrices() {
        var result = serviceAPI.obtenerLista()        
        result.then((response) => {
          var data = response.data    
          data.forEach(element => {
            if(element.active) {
              this.arr.listPrices.push(element)
            }
          });
        })  
      },
      getUtility(item) {
        return item.utility / 100 
      },
      showConvert(item) {       
        this.itemSelectedConvert = '(' + item.code + ') ' + item.name    
        this.crud.formConvert.id = item.id

        this.crud.formConvert.price_list = item.price_list
        this.crud.formConvert.percentage_discount = item.percentage_discount 
        this.crud.formConvert.percentage_discount_1 = item.percentage_discount_1 
        this.crud.formConvert.percentage_discount_2 = item.percentage_discount_2 
        this.crud.formConvert.additional_percentage = item.additional_percentage
        this.crud.formConvert.additional_percentage_1 = item.additional_percentage_1
        this.crud.formConvert.additional = item.additional        
        this.crud.formConvert.unit_measurement_purchase = item.unit_measurement_purchase
        this.crud.formConvert.unit_measurement_purchase_id = item.unit_measurement_purchase_id

        if(item.products_id) {
          this.crud.formConvert.changeType = false
        }
        else {
          this.crud.formConvert.changeType = true
        }

        if(item.products_id) {
          this.crud.formConvert.type = 'ASOCIAR'
          this.crud.formConvert.products_id = item.products_id
          this.crud.formConvert.products = item.product
          
          this.crud.formConvert.unit_measurement_sale = item.product.unit_measurement_sale
          this.crud.formConvert.unit_measurement_sale_id = item.product.unit_measurement_sale_id
          this.crud.formConvert.unit_measurement_sale_conversion = item.product.unit_measurement_sale_conversion          
        } else {
          this.crud.formConvert.type = 'NUEVO'
          this.crud.formConvert.code = item.code      
        }        

        this.arr.listPrices.forEach(element => {
          this.crud.formConvert.lists[element.id] = 0
        })

        this.arr.listPrices.forEach(element => {
          if(this.crud.formConvert.products_id) {
            var result1 = serviceAPI.mostrarProductosByLista({
              price_list_id: element.id,
              products_id: this.crud.formConvert.products_id,
            })        
            result1.then((response1) => {
              var data1 = response1.data
              this.crud.formConvert.lists[element.id] = parseFloat(data1.products.utility)
              this.convertPriceForceUpdate()
            })                                                
          }
        });

        this.modal.formConvert.title = "Configurar Producto"
        this.modal.formConvert.active = true        
      },
      changeTypeConvert() {
        if(this.crud.formConvert.type == 'NUEVO') {
          this.crud.formConvert.products_id = 0
          this.crud.formConvert.products = null
        }
        if(this.crud.formConvert.type == 'ASOCIAR') {
          this.crud.formConvert.code = ''
        }        
      },
      loadProductsConvert(object) {
        if(object){
          this.crud.formConvert.products = object
          this.crud.formConvert.products_id = object.id
        } else {
          this.crud.formConvert.products = null
          this.crud.formConvert.products_id = 0
        }
      },
      loadUnitMeasurementPurchase(object) {
        if(object){
          this.crud.formConvert.unit_measurement_purchase = object
          this.crud.formConvert.unit_measurement_purchase_id = object.id  
        } else {
          this.crud.formConvert.unit_measurement_purchase = null
          this.crud.formConvert.unit_measurement_purchase_id = 0
        }
      },
      loadUnitMeasurementSale(object) {
        if(object){
          this.crud.formConvert.unit_measurement_sale = object
          this.crud.formConvert.unit_measurement_sale_id = object.id
        } else {
          this.crud.formConvert.unit_measurement_sale = null
          this.crud.formConvert.unit_measurement_sale_id = 0
        }
      },      
      saveConvert() {        
        if(this.crud.formConvert.type=='ASOCIAR') {
          if(!this.crud.formConvert.products_id) {
            this.$awn.alert("No se cargó la relación con el producto");
            return false
          }
        }

        if(this.crud.formConvert.type=='NUEVO') {
          if(!this.crud.formConvert.code) {            
            this.$awn.alert("No se cargo el código");
            return false            
          }
        }        
        
        this.$bvModal.msgBoxConfirm('¿Configurar Producto de Proveedor relacionado con Producto de Venta? Este proceso puede demorar unos minutos.', {
          title: 'Configurar Producto de Proveedor',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'ASOCIAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {            
            let loader = this.$loading.show();
            
            var result = serviceAPI.convertProduct(this.crud.formConvert);

            result.then((response) => {              
              this.modal.formConvert.active = false              
              loader.hide()
              this.filterLoad()
              this.loadSuppliers()
              this.selectedItem(this.filterQuery, true)
              this.$awn.success("Registro configurado con éxito");
            })
            .catch(error => {                     
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {    
          this.$awn.alert(Error.showError(error));
        })            
      },
      convertPriceForceUpdate() {
        this.convertPriceForceUpdateKey = this.convertPriceForceUpdateKey + 1
      },

      // MARCAR POR DEFAULT
      setDefault(item) {        
        this.$bvModal.msgBoxConfirm('¿Marcar producto de proveedor por Default para obtener el precio de venta?', {
          title: 'Marcar Por Default',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'MARCAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {            
            let loader = this.$loading.show();
            
            var result = serviceAPI.marcarDefault({
              id: item.id
            });

            result.then((response) => {                            
              loader.hide()
              this.filterLoad()
              this.loadSuppliers()
              this.selectedItem(this.filterQuery, true)
              this.$awn.success("Registro marcado con éxito");
            })
            .catch(error => {                     
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {    
          this.$awn.alert(Error.showError(error));
        })    
      },

      // COMPARAR PRECIOS
      showCompare(item) {              
        this.tableCompare.isBusy = true
        this.itemSelectedCompare = '(' + item.product.code + ') ' + item.product.name
                
        var result = serviceAPI.compareProductos({
          products_id: item.products_id,
        })        
        result.then((response) => {
          var data = response.data
          this.tableCompare.items = data        
        })                  

        this.modal.formCompare.title = "Comparar precios con otros proveedores"
        this.modal.formCompare.active = true
        this.tableCompare.isBusy = false
      },
      closeCompare() {
        this.modal.formCompare.active = false
      },  
    }       
  }
</script>
<style>
  .crud-price-list-suppliers-table-action button {
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .crud-price-list-suppliers-table-action i {
    color: #23282c;
  }
  .crud-price-list-suppliers-download {
    float: left;
    margin-right: 10px;    
  }  
</style>